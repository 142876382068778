import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"

export function useMonitoringPresetBreadcrumbs({
  editName,
}: { editName?: string } = {}) {
  const { t, langKeys, tPlaceholder } = useTranslate()

  const base = [
    {
      to: Routes.Settings.location(),
      label: t(langKeys.settings),
    },
    {
      to: Routes.SettingsPresets.location(),
      label: tPlaceholder("Monitoring presets"),
    },
  ]

  const noise = [
    ...base,
    {
      to: Routes.SettingsPresetsNoise.location(),
      label: tPlaceholder("Noise monitoring"),
    },
  ]

  const noiseEdit = [...noise, { to: { pathname: "#" }, label: editName ?? "" }]

  return { base, noise, noiseEdit }
}
